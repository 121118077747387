import React from 'react';
import './App.css';
import './assets/poppins/poppins.css';
import 'dotenv';

import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes';
import {BrowserRouter} from "react-router-dom";
import DommusPromiseLoading from "./components/DommusLoading/DommusPromiseLoading";
import { ContextoGlobal } from './components/ContextoGlobal';
import { ContextoRelatorio } from './components/ContextoRelatorio';
import { AssistenciaTecnicaContextProvider } from './components/AssistenciaTecnicaContext';
import { QuestionarioProvider } from './contexts/QuestionarioContext';
import { PerguntaProvider } from './contexts/PerguntaContext';
import { ConfiguracaoProvider } from './contexts/ConfiguracaoContext';
import { FluxoEtapaProvider } from './contexts/FluxoEtapaContext';
import { FaqPortalClienteProvider } from './contexts/FaqPortalClienteContext';
import { AutomacaoProvider } from './contexts/AutomacaoContext';
import { ConectaProvider } from './contexts/ConectaContext';

function App() {

	return (
		<div>
			<ContextoGlobal>
				<ContextoRelatorio>
					<AssistenciaTecnicaContextProvider>
						<QuestionarioProvider>
							<PerguntaProvider>
								<ConfiguracaoProvider>
                                  <FluxoEtapaProvider>
                                    <FaqPortalClienteProvider>
									 <AutomacaoProvider>
                                        <ConectaProvider>
                                          <BrowserRouter>
                                            <DommusPromiseLoading />
                                            <Routes/>
                                          </BrowserRouter>
                                        </ConectaProvider>
									  </AutomacaoProvider>
                                    </FaqPortalClienteProvider>
                                  </FluxoEtapaProvider>
								</ConfiguracaoProvider>
							</PerguntaProvider>
						</QuestionarioProvider>
					</AssistenciaTecnicaContextProvider>
				</ContextoRelatorio>
			</ContextoGlobal>
		</div>

	);
}

export default App;
