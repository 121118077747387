import React, { createContext, ReactElement, useState, Dispatch, SetStateAction } from "react";
import { FaqPortalClienteType, WidgetPortalType } from "./type";

interface FaqPortalClienteContextProps {
  listaFaqs: FaqPortalClienteType[];
  setListaFaqs: Dispatch<SetStateAction<FaqPortalClienteType[]>>;
  listaWidges: WidgetPortalType[];
  setListaWidges: Dispatch<SetStateAction<WidgetPortalType[]>>;
  faqSelecionado: FaqPortalClienteType;
  setFaqSelecionado: Dispatch<SetStateAction<FaqPortalClienteType>>;
  handleAtualizarFaq: (idFaq:number, value:FaqPortalClienteType) => void;
  handleAdicionarFaq: (faq:FaqPortalClienteType) => void;
  handleRemoveFaq: (idFaq:number) => void;
}

export const FaqPortalClienteContext = createContext({} as FaqPortalClienteContextProps);

export function FaqPortalClienteProvider({ children }: { children: ReactElement }) {
  const [listaFaqs, setListaFaqs] = useState<FaqPortalClienteType[]>([] as FaqPortalClienteType[])
  const [listaWidges, setListaWidges] = useState<WidgetPortalType[]>([] as WidgetPortalType[])
  const [faqSelecionado, setFaqSelecionado] = useState<FaqPortalClienteType>({} as FaqPortalClienteType);

  const handleAtualizarFaq = (faqId:number, value:FaqPortalClienteType) => {
    setListaFaqs((current) =>{
      return current.map((item)=>{
        if(Number(item.id_faq_widget) === faqId){
          return value;
        }
        return item;
      })
    })
  }

  const handleAdicionarFaq = (novoFaq:FaqPortalClienteType)=>{
    setListaFaqs((current) => [novoFaq, ...current]);
  }

  const handleRemoveFaq = (idFaq:number) => {
    setListaFaqs((current) => current.filter((item) => Number(item.id_faq_widget) !== idFaq));
  }

  return (
      <FaqPortalClienteContext.Provider
          value={{
            listaFaqs,
            setListaFaqs,
            listaWidges,
            setListaWidges,
            faqSelecionado,
            setFaqSelecionado,
            handleAtualizarFaq,
            handleAdicionarFaq,
            handleRemoveFaq
          }}
      >
          {children}
      </FaqPortalClienteContext.Provider>
  );
}
