import React, { createContext, ReactElement, useState, Dispatch, SetStateAction } from "react";
import { AutomacaoType, AutomacaoTipoType, AutomacaoGatilhoTipo} from "./types";

interface AutomacaoContextProps {
  automacoes: AutomacaoType[];
  setAutomacoes: Dispatch<SetStateAction<AutomacaoType[]>>
  automacaoSelecionada: AutomacaoType;
  setAutomacaoSelecionada: Dispatch<SetStateAction<AutomacaoType>>;
  automacaoTipos: AutomacaoTipoType[];
  setAutomacaoTipos: Dispatch<SetStateAction<AutomacaoTipoType[]>>;
  automacaoGatilhoTipos: AutomacaoGatilhoTipo[];
  setAutomacaoGatilhoTipos: Dispatch<SetStateAction<AutomacaoGatilhoTipo[]>>;
  handleAdicionarAutomacao: (automacao:AutomacaoType) => void;
  handleAtualizarAutomacao: (idAutomacao:number, automacao:AutomacaoType) => void;
  handleRemoveAutomacao: (idAutomacao:number) => void;
}

export const AutomacaoContext = createContext({} as AutomacaoContextProps);

export function AutomacaoProvider({ children }: { children: ReactElement }) {
  const [automacoes, setAutomacoes] = useState<AutomacaoType[]>([] as AutomacaoType[])
  const [automacaoTipos, setAutomacaoTipos] = useState<AutomacaoTipoType[]>([] as AutomacaoTipoType[]);
  const [automacaoGatilhoTipos, setAutomacaoGatilhoTipos] = useState<AutomacaoGatilhoTipo[]>([] as AutomacaoGatilhoTipo[]);
  const [automacaoSelecionada, setAutomacaoSelecionada] = useState<AutomacaoType>({} as AutomacaoType);
  
  const handleAdicionarAutomacao = (automacao:AutomacaoType)=>{
    setAutomacoes((current) => [automacao, ...current]);
  }

  const handleAtualizarAutomacao = (idAutomacao:number, automacao:AutomacaoType) => {
    setAutomacoes((current) => {
      return current.map((item) => {
         if(item?.id_automacao === idAutomacao){
           return automacao;
         }
         return item;
      })
    })
  }

  const handleRemoveAutomacao = (idAutomacao:number) => {
    setAutomacoes((current) => current.filter((item) => item.id_automacao !== idAutomacao));
  }

  return (
      <AutomacaoContext.Provider
        value={{
          automacoes,
          setAutomacoes,
          automacaoSelecionada,
          setAutomacaoSelecionada,
          automacaoTipos,
          setAutomacaoTipos,
          automacaoGatilhoTipos,
          setAutomacaoGatilhoTipos,
          handleRemoveAutomacao,
          handleAdicionarAutomacao,
          handleAtualizarAutomacao,
        }}
      >
          {children}
      </AutomacaoContext.Provider>
  );
}
