import React, { createContext, ReactElement, useState, Dispatch, SetStateAction } from "react";
import { ContatoOrigemConectaType, MeioComunicacaoConectaType, SituacaoAtendimentoConectaType, TemplateConectaType, TipoAtendimentoConectaType } from "./type";

interface ConectaContextProps {
  listaMeioComunicacao: MeioComunicacaoConectaType[];
  setListaMeioComunicacao: Dispatch<SetStateAction<MeioComunicacaoConectaType[]>>;
  listaTipoAtendimento: TipoAtendimentoConectaType[];
  setListaTipoAtendimento: Dispatch<SetStateAction<TipoAtendimentoConectaType[]>>;
  listaTemplates: TemplateConectaType[];
  setListaTemplates: Dispatch<SetStateAction<TemplateConectaType[]>>;
  listaSituacaoAtendimento: SituacaoAtendimentoConectaType[];
  setlistaSituacaoAtendimento: Dispatch<SetStateAction<SituacaoAtendimentoConectaType[]>>;
  listaContatoOrigem: ContatoOrigemConectaType[];
  setListaContatoOrigem: Dispatch<SetStateAction<ContatoOrigemConectaType[]>>
}

export const ConectaContext = createContext({} as ConectaContextProps);

export function ConectaProvider({ children }: { children: ReactElement }) {
  const [listaMeioComunicacao, setListaMeioComunicacao] = useState<MeioComunicacaoConectaType[]>([]);
  const [listaTipoAtendimento, setListaTipoAtendimento] = useState<TipoAtendimentoConectaType[]>([]);
  const [listaTemplates, setListaTemplates] = useState<TemplateConectaType[]>([]);
  const [listaSituacaoAtendimento, setlistaSituacaoAtendimento] = useState<SituacaoAtendimentoConectaType[]>([]);
  const [listaContatoOrigem, setListaContatoOrigem] = useState<ContatoOrigemConectaType[]>([])

  return (
      <ConectaContext.Provider
        value={{
          listaMeioComunicacao,
          setListaMeioComunicacao,
          listaTipoAtendimento,
          setListaTipoAtendimento,
          listaTemplates,
          setListaTemplates,
          listaSituacaoAtendimento,
          setlistaSituacaoAtendimento,
          listaContatoOrigem,
          setListaContatoOrigem,
        }}
      >
          {children}
      </ConectaContext.Provider>
  );
}
