import React, { useContext, useEffect, useState } from "react";
import { TicketType } from "../../../../../../types/TicketType";
import { Accordion, Alert, Card } from "react-bootstrap";
import "./index.css";
import { QuestionarioType } from "../../../../../../contexts/QuestionarioContext/types";
import { trackPromise } from "react-promise-tracker";
import QuestionarioService from "../../../../../../services/QuestionarioService";
import Swal from "sweetalert2";
import { ChamadoContext } from "../../../../../../components/ContextoGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ModalDommus from "../../../../../../components/Modal";
import { GeracaoLinkQuestionarioPublico } from "../../../../../../components/GeracaoLinkQuestionarioPublico";
import { ListaQuestionariosPublicoTicket } from "./ListaQuestionariosPublicoTicket";

interface QuestionariosTicketProps {
    ticket: TicketType;
}

export function QuestionariosTicket({ ticket }: QuestionariosTicketProps) {
    const [activeKey, setActiveKey] = useState(null);
    const [questionarios, setQuestionario] = useState<QuestionarioType[]>([] as QuestionarioType[]);
    const [questionarioSelecionado, setQuestionarioSelecionado] = useState<QuestionarioType>({} as QuestionarioType);
    const [openModalGerarLink, setOpenModalGerarLink] = useState(false);
    const { processoTicketSelecionado, permissoesQuestionarioTicket }: any = useContext(ChamadoContext);

    const handleSelectedKey = (key: any) => {
        setActiveKey(key);
    }

    useEffect(() => {
        if (ticket?.id_ticket) {
            trackPromise(QuestionarioService.buscarQuestionariosPorTicket(ticket.id_ticket, processoTicketSelecionado?.processo_empreendimento ?? null)).then((response: any) => {
                setQuestionario(response.data);
            }).catch((error) => {
                Swal.fire(
                    'Ooops... Ocorreu um erro ao carregar questionários.',
                    '',
                    'error'
                );
            })
        }
    }, [ticket])

    return (
        <>
            {questionarios.length ?
                <Accordion onSelect={handleSelectedKey}>
                    {questionarios.map((questionario, index) => {
                        const eventKey = index.toString();
                        return (
                            <Card key={index}>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={eventKey}
                                    className={`${activeKey === eventKey ? "item-colapse-selecionado" : ""}`}
                                >
                                    <div className="header-card-questionario">
                                        {questionario.descricao}
                                        {permissoesQuestionarioTicket?.gerar && 
                                            <button
                                                className="btn btn-gerar-formulario"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setQuestionarioSelecionado(questionario);
                                                    setOpenModalGerarLink(true)
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faFile as IconProp} /> Gerar Formulário
                                            </button>
                                        }
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={eventKey}>
                                    <Card.Body
                                        style={{ display: "flex", justifyContent: "center", maxHeight: "50vh", overflowY: "auto" }}
                                    >{activeKey === eventKey && <ListaQuestionariosPublicoTicket ticket={ticket} questionario={questionario}/>}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )
                    })}
                </Accordion>
                : <Alert variant="warning">Nenhum questionário vinculado</Alert>}
            <ModalDommus
                open={openModalGerarLink}
                close={()=>setOpenModalGerarLink(false)}
                titulo="Geração formulário de respostas"
                size="md"
            >
                <GeracaoLinkQuestionarioPublico
                    ticket={ticket}
                    questionario={questionarioSelecionado}
                    closeModal={() => { setOpenModalGerarLink(false) }}
                />
            </ModalDommus>
        </>
    )
}
