import React, { createContext, useState } from 'react'
import { RecursoCategoriaType, RecursoType, RecursoUnidadeType } from './types'
import { errorToast } from '../DommusToast'
import empreendimentoService from '../../services/EmpreendimentoService'

export const ChamadoContext = createContext({})
export const EquipesContext = createContext({})


interface ContextoGlobalProps {
    children: JSX.Element
}

export function ContextoGlobal({children}: ContextoGlobalProps) {
    //Dados ChamadoContext
    const [ticketStatus, setTicketStatus] = useState([])
    const [ticketCategoria, setTicketCategoria] = useState([])
    const [ticketMotivo, setTicketMotivo] = useState([])
    const [ticketPrioridade, setTicketPrioridade] = useState([])
    const [ticketTipo, setTicketTipo] = useState([])
    const [ticketTags, setTicketTags] = useState([])
    const [ticketSubStatus, setTicketSubStatus] = useState([])
    const [filas, setFilas] = useState([])
    const [processoTicketSelecionado, setProcessoTicketSelecionado] = useState(null)
    const [dadosEmpreendimentoTicketSelecionado, setDadosEmpreendimentoTicketSelecionado] = useState([])
    const [expedienteCalculoPrazo, setExpedienteCalculoPrazo] = useState(null);
    const [permissoesQuestionarioTicket, setPermissoesQuestionarioTicket] = useState(null);
    const [permissoesTicket, setPermissoesTicket] = useState(null);
    //Dados EquipesContext
    const [usuarios, setUsuarios] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [empreendimentos, setEmpreendimentos] = useState<any[]>([])
    const [tiposNotificacoes, setTipoNotificacoes] = useState([])
    const [listaTipoBuscaProcesso, setListaTipoBuscaProcesso] = useState({})
    const [limiteCaracteresNotaInterna, setLimiteCaracteresInternas] = useState({});
    const [checkCampoObrigatorioSteps, setCheckCampoObrigatorioSteps] = useState({});
    const [listaRecursos, setListaRecursos] = useState<RecursoType[]>([]);
    const [listaRecursoCategorias, setListaRecursoCategorias] = useState<RecursoCategoriaType[]>([]);
    const [listaRecursoUnidades, setListaRecursoUnidades] = useState<RecursoUnidadeType[]>([]);
    const [condominios, setCondominios] = useState([])

    const alterarStatusRequiredAssistencia = (step:string, name:string, status:boolean)=>{
        if(step && name){
            setCheckCampoObrigatorioSteps((current:any)=>{
                if(current[step]){
                    current[step][name] = status
                }else{
                    current[step] = {
                        [name]: status
                    }
                }
                return current;
            });
        }
    }

    const atualizarListaRecursoUnidades = (unidadeNew:RecursoUnidadeType)=>{
        if(!listaRecursoUnidades.find((unidade)=>{return unidadeNew.id_recurso_unidade === unidade.id_recurso_unidade})){
            setListaRecursoUnidades((current)=> {return [...current, unidadeNew]})
        }
    }

    const atualizarListaRecursos = (recursoNew: RecursoType)=>{
        if(!listaRecursos.find((recurso)=>{return recursoNew.id_recurso === recurso.id_recurso})){
            setListaRecursos((current)=>[...current, recursoNew]);
        }
    }

    const atualizarListaRecursoCategorias = (categoriaNew:RecursoCategoriaType) => {
        if(!listaRecursoCategorias.find((categoria)=>{return categoriaNew.id_recurso_categoria === categoria.id_recurso_categoria})){
            setListaRecursoCategorias((current)=>[...current, categoriaNew]);
        }
    }

    function buscarCondominios() {
        if(condominios.length) {
            return
        }
        empreendimentoService.buscarCondominios()
            .then(response => setCondominios(response.data))
            .catch(error => {
                console.log(error)
                console.log(error?.response)
                errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados dos condominios' })
            })
    }

    const resolverBlocosEmpreendimento = (idEmpreendimento: any) => {
      let blocos: Array<any> = [];
      Array.isArray(empreendimentos) && 
      empreendimentos.forEach(empreendimento => {
        if(Number(empreendimento?.id) == Number(idEmpreendimento)){
          blocos = (empreendimento?.blocos?.length && empreendimento.blocos.slice()) || [];  
        }
      })
      return blocos;
    }

    const resolverEmpreendimento = (idEmpreendimento:number)=>{
      return empreendimentos?.find((item) => Number(item?.id) === idEmpreendimento);
    }

    return (
        <ChamadoContext.Provider
            value={{
                ticketStatus,
                setTicketStatus,
                ticketCategoria,
                setTicketCategoria,
                ticketMotivo,
                setTicketMotivo,
                ticketPrioridade,
                setTicketPrioridade,
                ticketTipo,
                setTicketTipo,
                ticketTags,
                setTicketTags,
                ticketSubStatus,
                setTicketSubStatus,
                filas,
                setFilas,
                processoTicketSelecionado,
                setProcessoTicketSelecionado,
                dadosEmpreendimentoTicketSelecionado,
                setDadosEmpreendimentoTicketSelecionado,
                expedienteCalculoPrazo,
                setExpedienteCalculoPrazo,
                listaTipoBuscaProcesso,
                setListaTipoBuscaProcesso,
                checkCampoObrigatorioSteps,
                setCheckCampoObrigatorioSteps,
                alterarStatusRequiredAssistencia,
                limiteCaracteresNotaInterna,
                setLimiteCaracteresInternas,
                listaRecursos,
                setListaRecursos,
                listaRecursoCategorias,
                setListaRecursoCategorias,
                listaRecursoUnidades,
                setListaRecursoUnidades,
                atualizarListaRecursoUnidades,
                atualizarListaRecursoCategorias,
                atualizarListaRecursos,
                permissoesQuestionarioTicket, 
                setPermissoesQuestionarioTicket,
                empreendimentos, 
                setEmpreendimentos,
                resolverBlocosEmpreendimento,
                permissoesTicket,
                setPermissoesTicket
            }}
        >
            <EquipesContext.Provider
                value={{
                    usuarios, setUsuarios,
                    departamentos, setDepartamentos,
                    empreendimentos, setEmpreendimentos,
                    filas, setFilas,
                    tiposNotificacoes, setTipoNotificacoes,
                    condominios, buscarCondominios,
                    resolverEmpreendimento,
                }}
            >
                    {children}
            </EquipesContext.Provider>
        </ChamadoContext.Provider>
    )
}
