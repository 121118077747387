import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import AnexoIcone from "./Anexos/AnexoIcone";
import ticketService from "../../../../../services/TicketService";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../../../components/DommusToast";
import DOMPurify from 'dompurify';

export default function Mensagens({ mensagens, idTicket }) {
  const mensagemListRef = useRef(null);
  const [mensagensState, setMensagensState] = useState([])

  useEffect(() => {
    setMensagensState([])
  }, [idTicket])

  useEffect(()=>{
    if(mensagens.length && mensagens.length !== mensagensState.length) {
      let element = mensagemListRef?.current;
      if(element){
        element.scrollTop = element.scrollHeight;
      }
      setMensagensState(mensagens)
    }
  }, [mensagens])

  function openImage(url) {
    window.open(url, "_blank");
  }

  function buscarArquivoPorNome(nomeArquivo) {
    if(!nomeArquivo) {
      errorToast.fire({ text: 'Arquivo não encontrado' })

      return
    }
    trackPromise(ticketService.buscarArquivoPorNome(nomeArquivo))
      .then(response => {
        if(!response.data.url) {
          errorToast.fire({ text: 'Arquivo não encontrado' })

          return
        }
        window.open(response.data?.url, '_blank')
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        errorToast.fire({ text: 'Arquivo não encontrado' })
      })
  }

  function itemAnexo(arquivo){
    return(
      <div className="anexo-section" onClick={()=> arquivo?.url && window.open(arquivo.url, '_blank') || buscarArquivoPorNome(arquivo?.file_name)}>
        <AnexoIcone anexo={arquivo?.nome?.split('.').pop()} style={{ fontSize: "1rem" }} />
        <span>{arquivo?.nome}</span>
      </div>
    )
  }

  function renderMensagem(mensagem, index) {
    const date = moment(mensagem.criado_em).format("DD/MM/YYYY, HH:mm");
    const { tipo, nome, text, url, tipo_upload, arquivos } = mensagem;
    const className = tipo === 'A' ? "Messages-message currentMember" : "Messages-message";
      
    
    return (
      <li className={className} key={index}>
        <div className="Message-content">
          <div className="username">{date + " " + nome}</div>
          <div className="text">{
          url 
          ? tipo_upload == 'I' 
          ? <img onClick={() => openImage(url)} style={{maxWidth: "100%", cursor: "pointer"}} src={url} alt={text} /> 
          : <div onClick={() => openImage(url)} style={{ cursor: "pointer"}}>
               <FontAwesomeIcon icon={faPaperclip} color="white" />  {<div dangerouslySetInnerHTML={{ __html: text}}></div>}
            </div>
              : <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text?.replace(/\r\n|\r|\n/g, "<br/>"))}}></div>}
              {arquivos.length > 0 && 
                <>
                  <hr/>
                  {arquivos.map((arquivo, index)=>{
                    return (
                      <div key={index}>
                        {itemAnexo(arquivo)}
                      </div>
                    )
                  })}
                </>
              }
          </div>
        </div>
      </li>
    );
  }

  return (
    <ul ref={mensagemListRef} className="Messages-list">{mensagens.map((m, index) => renderMensagem(m, index))}</ul>
  );
}
