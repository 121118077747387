export type PerguntaType = {
    id_pergunta: number;
    enunciado: string;
    id_tipo_resposta_pergunta: number;
    tipo_resposta_pergunta: TipoRespostaPerguntaType;
    resposta?: any;
    options?: any[];
    criado_por: number;
    oculto: number;
    ativo: string;
}


export type TipoRespostaPerguntaType = {
    id_tipo_resposta_pergunta: number;
    nome: string;
}

export enum TipoRespostaEnum{
    TEXTO = 1,
    DATA = 2,
    CPF = 3,
    CEP = 4,
    TELEFONE = 5,
    MOEDA = 6,
    SIMPLES_SELECAO = 7,
    MULTIPLA_SELECAO = 8,
    CHECKBOX = 9,
    RADIO_BUTTON = 10,
}
